// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { Dispatch, FC, FormEvent, SetStateAction, useEffect, useState } from 'react';

import classNames from 'classnames';
import { motion } from 'framer-motion';
import { HTTPError } from 'ky';
import { useQueryClient } from 'react-query';
import { BottomSheet } from 'react-spring-bottom-sheet';

import Button from '@components/Button';
import Input from '@components/Input';
import SecureText from '@components/SecureText';
import { ENDPOINTS } from '@configs/api.config';
import { CountryCode } from '@configs/onBoarding.config';
import { useSendOtpMutation } from '@hooks/mutations';
import useAnalyticsManager from '@hooks/useAnalyticsManager';
import { SelectedCountry } from '@libs/types';
import { GetNestedObjectKeyValue } from '@utils/general';

import OtpLimitExceeded from './OtpLimitExceeded';

interface Props {
  mobileNumber: string;
  setMobileNumber: Dispatch<SetStateAction<string>>;
  setOnboardingStep: Dispatch<SetStateAction<number>>;
  countryCodeData: SelectedCountry;
  setCountryData: Dispatch<SetStateAction<SelectedCountry>>;
  setAttempt: (value: number) => void;
}

// convert the data to a flat 1d array
let FORMATTED_COUNTRY_CODE_DATA: any = [];

const StepMobile: FC<Props> = ({
  mobileNumber,
  setMobileNumber,
  setOnboardingStep,
  countryCodeData,
  setCountryData,
  setAttempt,
}) => {
  const queryClient = useQueryClient();

  const generalConfig = queryClient.getQueriesData(ENDPOINTS.GENERAL_CONFIG);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [countyCodeBS, setCountryCodeBS] = useState(false);

  const [inputValue, setInputValue] = useState(''); // Input values in country search list

  const [otpLimitExceed, setOtpLimitExceed] = useState(false);

  const [countryList, setCountryList] = useState([...FORMATTED_COUNTRY_CODE_DATA]); // country list

  const analyticsManager = useAnalyticsManager();

  const [errorCode, setErrorCode] = useState('');

  // convert the data to a flat 1d array and set in state countryList
  useEffect(() => {
    CountryCode?.forEach((arr) => {
      FORMATTED_COUNTRY_CODE_DATA = [...FORMATTED_COUNTRY_CODE_DATA, ...arr.data];
    });
    setCountryList(FORMATTED_COUNTRY_CODE_DATA);
  }, []);

  const sendOtpMutation = useSendOtpMutation({
    // onMutate: () => setOnboardingStep((step) => step + 1), // OPTIMISTIC UI
    onError: async (err: HTTPError) => {
      const errorData = await err?.response?.json();
      setErrorCode(errorData?.code);
      if (
        errorData?.code === 'AUTH0001' ||
        errorData?.code === 'AUTH0002' ||
        errorData?.code === 'AUTH0003'
      ) {
        setOtpLimitExceed(true);
      }
    },
    onSuccess: async (data: any) => {
      const otpData = await data?.json();
      setAttempt(otpData?.current_session_attempts);
      setOnboardingStep((step) => step + 1);
      analyticsManager.sendEvent('otp_sent_web', null, ['mixpanel']);
    },
  });

  // Handles continue button clicks check validation and calls the api
  const handleNext = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isButtonEnabled) return;

    analyticsManager.sendEvent('mobile_number_submit_web', null, 'all'); // sends events to all (branch, mixPanel, clevertap)
    sendOtpMutation.mutate({ mobile: mobileNumber, medium: 'SMS', isdCode: countryCodeData.code });
  };

  // checks validation each time there is change in mobileNumber
  useEffect(() => {
    setIsButtonEnabled(mobileNumber.length === Number(countryCodeData.numberOfDigits));
  }, [mobileNumber, setIsButtonEnabled]);

  // fires event when user lands on the Mob number screen
  useEffect(() => {
    analyticsManager.sendEvent('mobile_no_web', null, ['mixpanel']);
  }, []);

  // handles changes in country code  and closes the Bottom sheet
  const handleCountryCodeChange = (value) => {
    setCountryData(FORMATTED_COUNTRY_CODE_DATA.find(({ id }) => id === value.id));
    setCountryCodeBS(false);
  };

  // handles input change in search input box, and filters the country list
  const handleInputChange = (event) => {
    setInputValue(event);
    const countryListFiltered = FORMATTED_COUNTRY_CODE_DATA?.filter((i) =>
      i.name?.toLowerCase().includes(event)
    );
    setCountryList(countryListFiltered);
  };

  return (
    <>
      <form onSubmit={handleNext} className="relative z-10 flex flex-col flex-1 pt-24 pb-6 ">
        <main className="flex flex-col flex-1 space-y-5">
          <h4>Enter phone number</h4>
          <div
            className="flex items-center px-5 py-3 space-x-4 bg-white rounded-full shadow text-h6"
            onClick={() => setCountryCodeBS(true)}
          >
            <span className="pr-4 border-r border-grayShadow">{countryCodeData?.code}</span>
            <span className="flex-1">{countryCodeData?.name}</span>
            <ArrowDown isOpen={countyCodeBS} />
          </div>
          <Input
            rounded
            placeholder="Enter your mobile number"
            setValue={setMobileNumber}
            value={mobileNumber}
            maxLength={Number(countryCodeData.numberOfDigits)}
            required
            onlyNumber
          />
        </main>

        <SecureText />

        {/* Continue Button */}
        <Button
          className="w-full"
          label="Continue"
          type="submit"
          disabled={!isButtonEnabled}
          spanId="onboarding-continue-mobile"
        />

        {/* Bottom sheet for selecting country code Starts Here */}
        <BottomSheet
          open={countyCodeBS}
          onDismiss={() => setCountryCodeBS(false)}
          snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 24]}
        >
          <div className="p-2">
            {/* SEARCH INPUT */}
            <div className=" fixed justify-center top-6 w-[95%]">
              <Input
                placeholder="Enter country name"
                searchField
                value={inputValue}
                setValue={handleInputChange}
                rounded
              />
            </div>

            {/* DATA_LIST  */}

            <div className="flex flex-col items-start px-3 py-1 mt-2 space-y-1 text-sm pt-9">
              {countryList.map((item, index) => (
                <button
                  onClick={() => {
                    handleCountryCodeChange(item);
                  }}
                  key={index}
                  className={classNames(
                    'flex space-x-2 border-b-2 w-[100%] items-center justify-start, pt-2 pb-2'
                  )}
                >
                  <span className="text-[#333]">{item?.name}</span>
                </button>
              ))}
            </div>
          </div>
        </BottomSheet>

        {/* Bottom sheet for selecting country code ends here */}
      </form>
      {otpLimitExceed && (
        <OtpLimitExceeded
          ifOtpExhausted={errorCode === 'AUTH0003'}
          onClose={() => setOtpLimitExceed(false)}
          contactUsDetails={{
            message: GetNestedObjectKeyValue(generalConfig, 'otp_expire', 'use_case'),
            number: GetNestedObjectKeyValue(generalConfig, 'customer_support', 'ai_match_making'),
          }}
        />
      )}
    </>
  );
};
export default StepMobile;

const ArrowDown = ({ isOpen }) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-4 h-4"
    viewBox="0 0 20 20"
    fill="currentColor"
    initial={false}
    animate={{
      rotate: isOpen ? 180 : 0,
      transition: {
        duration: 0.3,
        ease: 'easeInOut',
      },
    }}
  >
    <path
      className=""
      fillRule="evenodd"
      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
      clipRule="evenodd"
    />
  </motion.svg>
);
